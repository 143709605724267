import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Layout } from "../components";
import Cards from "../blocks/cards";
import { Icon } from "reflexjs";
import Block from "../components/cta.js";
import { Link } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout mdxType="Layout">
      <Block heading="Code Violations & Evictions" text="Landlords have an obligation to keep properties up to code and are prohibited from evicting a tenant for reporting building code violations. Identify evictions filed within 90 days of a building code violation and parcels with open orders upon eviction filing. These metrics are currently under active development. " mdxType="Block" /> 
      <Cards py="0" subheading="" heading="" text="" cards={[{
        heading: "City of Milwaukee Eviction Filings",
        text: "",
        image: {
          src: "/images/code-violations.png",
          alt: ""
        },
        link: <a display="inline-flex" alignItems="center" variant="text.link">
        <Link to="/ilpr/fifth-post/" mdxType="Link">Visualizations</Link>  <Icon name="arrow-right" size="4" ml="2" mdxType="Icon" />
        </a>
      }]} mdxType="Cards" />
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      